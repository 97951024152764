import React, { useState, useEffect, useContext } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, Collapse, ListItem, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { AuthContext } from "src/context/Auth";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: "rgba(0, 0, 0, 0.4)",
    padding: "13px 20px",
    justifyContent: "flex-start",
    textTransform: "none",
    marginBottom: "8px",
    letterSpacing: 0,
    width: "100%",
    fontWeight: "400",

    "&:hover": {
      color: "#ffff",
      background: " linear-gradient(254.2deg, #21FFBC -18.97%, #2613FF 97.92%)",
      borderRadius: "15px",

      "& $icon": {
        color: "#ffff !important",
      },
    },
  },
  buttonLeaf: {
    color: "rgba(0, 0, 0, 0.4)",
    padding: "13px 20px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    marginBottom: "5px",
    borderRadius: 0,
    opacity: 1,
    borderRadius: "15px",
    fontWeight: "400",
    fontSize: "12px",
    "& $icon": {
      color: "rgba(0, 0, 0, 0.4)",
    },

    "& li": {
      "& $title": {
        marginLeft: "30px",
      },
    },
    "&:hover": {
      color: "#ffff",
      background: " linear-gradient(254.2deg, #21FFBC -18.97%, #2613FF 97.92%)",
      borderRadius: "15px",

      "& $icon": {
        color: "#ffff",
      },
    },
    "&.depth-0": {
      "& $title": {
        fontWeight: 600,
        fontSize: "14px",
        breakWord: "word-break",
      },
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  title: {
    marginRight: "auto",
    fontSize: "14px",
    fontWeight: 600,
  },
  active: {
    color: "#ffff",
    background: " linear-gradient(254.2deg, #21FFBC -18.97%, #2613FF 97.92%)",
    borderRadius: "15px",
    fontWeight: theme.typography.fontWeightRegular,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: "#ffff",
    },
  },
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  tabview,
  setSelectedTab,
  bagde,
  ...rest
  
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const { pathname } = useLocation();

  const auth = useContext(AuthContext);

  const userData = auth.counting;

  useEffect(() => {
    if (tabview === "Sniper") {
      setSelectedTab(tabview);
    }
  }, [tabview]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          sx={{ fontSize: { sm: "10px", lg: "14px" }, fontWeight: 600 }}
          className={classes.button}
          onClick={handleToggle}
        >
          {Icon && <Icon className={classes.icon} size="20" color="#808080" />}
          <span className={classes.title}>{title}</span>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <>
      {" "}
      <ListItem
        className={clsx(classes.itemLeaf, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          activeClassName={classes.active}
          className={clsx(
            classes.buttonLeaf,
            `depth-${depth}`,
            pathname.includes("/money-transfer") &&  href.includes("/money-transfer") && classes.active 
          )}
          component={RouterLink}
          exact
          to={href}
        >
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{title}</span>
          {bagde && <span className={classes.icon}>{userData.length === 0 ? "":userData.length }</span>}
          {Info && <Info />}
        </Button>
      </ListItem>
    </>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  linkdata: PropTypes.string,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
